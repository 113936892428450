.App {
  max-width: 414px;
  margin: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.swal2-popup {
  border-radius: 0 !important;
}

.swal2-popup.swal2-toast .swal2-html-container {
  margin-top: 13px !important;
}

.swal2-popup.swal2-toast.swal2-show {
  animation: none !important;
}

.swal2-close:focus {
  box-shadow: none !important;
}

.react-html5-camera-photo>video {
  height: 100% !important;
}

#container-circles {
  position: fixed !important;
}

#outer-circle {
  width: 60px !important;
  height: 60px !important;
  left: -30px !important;
  background-color: #cecece !important;
}

#inner-circle {
  background-color: #cecece !important;
}

.react-pdf__Page {
  position: static !important;
}

.react-pdf__Page canvas {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  object-fit: contain !important;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid #0A62B3;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #0A62B3 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.MoneyHero {
  max-width: none;
}

#stepper-step .node {
  padding: 0px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.node-active {
  padding: 2px;
  border: 1px solid #FD9F40;
}

#stepper-step .node div:first-child {
  width: 20px;
  height: 20px;
}

.stepper {
  margin-left: 0 !important;
}